import { LOGOUT_USER } from '../Auth/Auth.constants';
import { LIVRAISON_ENREGISTRER, LIVRAISON_LISTER, LIVRAISON_SUPPRIMER } from './Livraison.constants';

const initialState = { pageNumero: 0 };

const LivraisonReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIVRAISON_LISTER:
    case LIVRAISON_ENREGISTRER:
    case LIVRAISON_SUPPRIMER:
      return {
        ...state,
        data: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

LivraisonReducer.hydrate = () => initialState;

export default LivraisonReducer;
