export const UPDATE_RAPPORT_DATAS = 'rapport/UPDATE_RAPPORT_DATAS';

export const listMois = [
  { code: 'JANUARY', value: 'Janvier' },
  { code: 'FEBRUARY', value: 'Février' },
  { code: 'MARCH', value: 'Mars' },
  { code: 'APRIL', value: 'Avril' },
  { code: 'MAY', value: 'Mai' },
  { code: 'JUNE', value: 'Juin' },
  { code: 'JULY', value: 'Juillet' },
  { code: 'AUGUST', value: 'Août' },
  { code: 'SEPTEMBER', value: 'Septembre' },
  { code: 'OCTOBER', value: 'Octobre' },
  { code: 'NOVEMBER', value: 'Novembre' },
  { code: 'DECEMBER', value: 'Décembre' }
];
