import { LOGOUT_USER } from '../Auth/Auth.constants';
import { PAIEMENT_ENREGISTRER } from '../Paiement/Paiement.constants';
import { CLEAN_DASHBOARD_DATAS } from '../Dashboard/Dashboard.constants';
import {
  COMMANDE_ENREGISTRER,
  COMMANDE_RECHERCHER,
  LIRE_COMMANDE,
  SAVE_FILTER,
  CHANGE_CLOSE_POPUP
} from './Commandes.constants';

const initialState = { pageNumero: 0, closePopup: false };

function updateCommande(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { data: newData };
}

const CommandeReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMANDE_ENREGISTRER:
    case PAIEMENT_ENREGISTRER:
      return {
        ...state,
        closePopup: true,
        list: undefined,
        data: undefined
      };
    case CHANGE_CLOSE_POPUP:
      return {
        ...state,
        closePopup: action.data
      };
    case COMMANDE_RECHERCHER:
      return {
        ...state,
        list: action.data,
        pageNumero: action.pageNumero
      };
    case LIRE_COMMANDE:
      return {
        ...state,
        ...updateCommande(state.data, action.data, action.numero)
      };

    case LOGOUT_USER:
      return {
        ...initialState
      };
    case CLEAN_DASHBOARD_DATAS:
      return {
        ...state,
        list: undefined,
        pageNumero: 0
      };
    case SAVE_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};

CommandeReducer.hydrate = () => initialState;

export default CommandeReducer;
