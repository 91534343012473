import { LISTER_DOCUMENTS, UPLAOD_DOCUMENTS, UPLAOD_LOGO, SUPPRIMER_DOCUMENTS } from './Documents.constants';
import { fetchApiAction } from '../../../setup/Api/api';

export function listerDocuments(type, numero, payload) {
  return (dispatch) => {
    dispatch(
      fetchApiAction('documents:lister', { body: payload }, (result) => ({
        type: LISTER_DOCUMENTS,
        data: result,
        numero,
        typeDocument: type
      }))
    );
  };
}

export function supprimerDocuments(payload) {
  return (dispatch) => {
    dispatch(
      fetchApiAction('documents:supprimer', { body: payload }, (result) => ({
        type: SUPPRIMER_DOCUMENTS,
        data: result
      }))
    );
  };
}

export function uploadPhoto(numero, payload) {
  return (dispatch) => {
    dispatch(
      fetchApiAction(
        'documents:upload',
        { body: payload },
        (result) => ({
          type: UPLAOD_DOCUMENTS,
          data: result,
          numero
        }),
        null,
        'Les photos ont été correctement chargées.'
      )
    );
  };
}

export function uploadLogo(payload) {
  return (dispatch) => {
    dispatch(
      fetchApiAction(
        'documents:uploadLogo',
        { body: payload },
        (result) => ({
          type: UPLAOD_LOGO,
          data: result
        }),
        null,
        'Le logo a été correctement chargé.'
      )
    );
  };
}
