import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'rapport', children: [{ path: '/', element: <Rapport /> }] },
        { path: 'livraisons', children: [{ path: '/', element: <Livraison /> }] },
        { path: 'infographie', children: [{ path: '/', element: <Infographie /> }] },
        { path: 'gestionlivraisons', children: [{ path: '/', element: <GestionLivraison /> }] },
        { path: 'gestioncategories', children: [{ path: '/', element: <GestionCategorie /> }] },
        { path: 'utilisateurs', children: [{ path: '/', element: <Utilisateurs /> }] },
        { path: 'clients', children: [{ path: '/', element: <Client /> }] },
        { path: 'clients', children: [{ path: '/:numero', element: <DetailClient /> }] },
        { path: 'articles', children: [{ path: '/', element: <Article /> }] },
        { path: 'articles', children: [{ path: '/:numero', element: <DetailArticle /> }] },
        { path: 'commandes', children: [{ path: '/', element: <Commande /> }] },
        { path: 'commandes', children: [{ path: '/:numero', element: <DetailCommande /> }] }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'contact-us', element: <Contact /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const Rapport = Loadable(lazy(() => import('../pages/rapport/Rapport')));
const Article = Loadable(lazy(() => import('../pages/article/Article')));
const DetailArticle = Loadable(lazy(() => import('../pages/article/detail/DetailArticle')));
const Client = Loadable(lazy(() => import('../pages/client/Client')));
const DetailClient = Loadable(lazy(() => import('../pages/client/detail/DetailClient')));
const Commande = Loadable(lazy(() => import('../pages/commande/Commande')));
const DetailCommande = Loadable(lazy(() => import('../pages/commande/detail/DetailCommande')));
const Livraison = Loadable(lazy(() => import('../pages/livraison/Livraison')));
const Infographie = Loadable(lazy(() => import('../pages/infographie/Infographie')));
const GestionLivraison = Loadable(lazy(() => import('../pages/gestionLivraison/GestionLivraison')));
const GestionCategorie = Loadable(lazy(() => import('../pages/gestionCategorie/GestionCategorie')));
const Utilisateurs = Loadable(lazy(() => import('../pages/authentication/utilisateurs/Utilisateurs')));
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
