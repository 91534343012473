import { LOGOUT_USER } from '../Auth/Auth.constants';
import { COMMANDE_ENREGISTRER } from '../Commandes/Commandes.constants';
import { CLEAN_DASHBOARD_DATAS } from '../Dashboard/Dashboard.constants';
import {
  CLIENT_CREER,
  LISTER_CLIENT,
  CLIENT_MODIFIER,
  CLIENT_RECHERCHER,
  LIRE_CLIENT,
  RECHERCHE_RAPIDE_CLIENT
} from './Clients.constants';

const initialState = { pageNumero: 0 };

function updateClient(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { data: newData };
}

const ClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_CREER:
    case CLIENT_MODIFIER:
      return {
        ...state,
        list: undefined,
        all: undefined,
        data: undefined
      };
    case COMMANDE_ENREGISTRER:
      return {
        ...state,
        list: undefined,
        data: undefined
      };
    case LISTER_CLIENT:
      return {
        ...state,
        all: action.data
      };
    case RECHERCHE_RAPIDE_CLIENT:
      return {
        ...state,
        rapide: action.data
      };
    case CLIENT_RECHERCHER:
      return {
        ...state,
        list: action.data,
        pageNumero: action.pageNumero
      };
    case LIRE_CLIENT:
      return {
        ...state,
        ...updateClient(state.data, action.data, action.numero)
      };

    case LOGOUT_USER:
      return {
        ...initialState
      };
    case CLEAN_DASHBOARD_DATAS:
      return {
        ...state,
        list: undefined,
        pageNumero: 0
      };
    default:
      return state;
  }
};

ClientReducer.hydrate = () => initialState;

export default ClientReducer;
