import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box component="img" alt="logo" src="/static/brand/logo_full.png" sx={{ height: 48, ...sx }} />;
}
