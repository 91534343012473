import { LOGOUT_USER } from '../Auth/Auth.constants';
import { PAIEMENT_LISTER, PAIEMENT_ENREGISTRER } from './Paiement.constants';

function updatePaiement(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { data: newData };
}

const initialState = { pageNumero: 0 };

const PaiementReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAIEMENT_LISTER:
    case PAIEMENT_ENREGISTRER:
      return {
        ...state,
        ...updatePaiement(state.data, action.data, action.numero)
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

PaiementReducer.hydrate = () => initialState;

export default PaiementReducer;
