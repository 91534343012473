import { UPDATE_RAPPORT_DATAS } from './Rapport.constants';
import { LOGOUT_USER } from '../Auth/Auth.constants';
import { COMMANDE_ENREGISTRER } from '../Commandes/Commandes.constants';
import { CLIENT_CREER, CLIENT_MODIFIER } from '../Clients/Clients.constants';
import { ARTICLE_ENREGISTRER, ARTICLE_SUPPRIMER } from '../Articles/Article.constants';
import { CLEAN_DASHBOARD_DATAS } from '../Dashboard/Dashboard.constants';

const initialState = {};

const RapportReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RAPPORT_DATAS:
      return {
        ...state,
        data: action.data
      };
    case CLIENT_CREER:
    case CLIENT_MODIFIER:
    case COMMANDE_ENREGISTRER:
    case CLEAN_DASHBOARD_DATAS:
    case ARTICLE_ENREGISTRER:
    case ARTICLE_SUPPRIMER:
      return {
        ...state,
        data: undefined
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

RapportReducer.hydrate = () => initialState;

export default RapportReducer;
