import { getConfig } from '../platform';

const baseApi = getConfig('apiUrl');

// eslint-disable-next-line
export default {
  'utilisateurs:lister': {
    url: `${baseApi}/utilisateur/lister`,
    method: 'GET',
    secure: true
  },
  'utilisateurs:creer': {
    url: `${baseApi}/utilisateur/creer`,
    method: 'POST',
    secure: true
  },
  'utilisateurs:modifier': {
    url: `${baseApi}/utilisateur/modifier`,
    method: 'POST',
    secure: true
  },
  'utilisateurs:debloquer': {
    url: (id) => `${baseApi}/utilisateur/structure/debloquer/${id}`,
    method: 'GET',
    secure: true
  },
  'utilisateurs:bloquer': {
    url: (id) => `${baseApi}/utilisateur/structure/bloquer/${id}`,
    method: 'GET',
    secure: true
  },
  'auth:login': {
    url: `${baseApi}/utilisateur/connexion`,
    method: 'POST'
  },
  'auth:refreshToken': {
    url: (refreshToken) => `${baseApi}/utilisateur/refreshToken?refreshToken=${refreshToken}`,
    method: 'GET'
  },
  'auth:mdpOublie': {
    url: (mail) => `${baseApi}/utilisateur/mdpOublie?identifiant=${mail}`,
    method: 'GET'
  },
  'auth:modifierMdp': {
    url: `${baseApi}/utilisateur/modifierMdp`,
    method: 'POST',
    secure: true
  },
  'dashboard:lire': {
    url: `${baseApi}/dashboard/lire`,
    method: 'GET',
    secure: true
  },
  'rapport:lire': {
    url: (param) => `${baseApi}/rapport/lire/${param.jour}/${param.mois}/${param.annee}`,
    method: 'GET',
    secure: true
  },
  'codification:lister': {
    url: (code) => `${baseApi}/codifications/lister/${code}`,
    method: 'GET',
    secure: true
  },
  'documents:uploadLogo': {
    url: `${baseApi}/documents/uploadLogo`,
    method: 'POST',
    secure: true,
    fileUpload: true
  },
  'documents:upload': {
    url: `${baseApi}/documents/upload`,
    method: 'POST',
    secure: true,
    fileUpload: true
  },
  'documents:lister': {
    url: `${baseApi}/documents/lister`,
    method: 'POST',
    secure: true
  },
  'documents:supprimer': {
    url: `${baseApi}/documents/supprimer`,
    method: 'POST',
    secure: true
  },
  'article:lire': {
    url: (numero) => `${baseApi}/article/${numero}`,
    method: 'GET',
    secure: true
  },
  'article:enregistrer': {
    url: () => `${baseApi}/article`,
    method: 'POST',
    secure: true
  },
  'article:lister': {
    url: () => `${baseApi}/article/lister`,
    method: 'GET',
    secure: true
  },
  'article:rechercher': {
    url: () => `${baseApi}/article/rechercher`,
    method: 'POST',
    secure: true
  },
  'article:supprimer': {
    url: (numero) => `${baseApi}/article/${numero}`,
    method: 'DELETE',
    secure: true
  },
  'client:creer': {
    url: () => `${baseApi}/client/creer`,
    method: 'POST',
    secure: true
  },
  'client:lire': {
    url: (numero) => `${baseApi}/client/lire/${numero}`,
    method: 'GET',
    secure: true
  },
  'client:rechercheRapide': {
    url: (predicat) => `${baseApi}/client/recherche/rapide/${predicat}`,
    method: 'GET',
    secure: true
  },
  'client:lister': {
    url: () => `${baseApi}/client`,
    method: 'GET',
    secure: true
  },
  'client:rechercher': {
    url: () => `${baseApi}/client/rechercher`,
    method: 'POST',
    secure: true
  },
  'client:modifier': {
    url: () => `${baseApi}/client/modifier`,
    method: 'POST',
    secure: true
  },
  'commande:lire': {
    url: (numero) => `${baseApi}/commande/${numero}`,
    method: 'GET',
    secure: true
  },
  'commande:rechercher': {
    url: () => `${baseApi}/commande/rechercher`,
    method: 'POST',
    secure: true
  },
  'commande:enregistrer': {
    url: () => `${baseApi}/commande`,
    method: 'POST',
    secure: true
  },
  'livraison:supprimer': {
    url: (commune) => `${baseApi}/livraison/supprimer/${commune}`,
    method: 'DELETE',
    secure: true
  },
  'livraison:enregistrer': {
    url: () => `${baseApi}/livraison/enregistrer`,
    method: 'POST',
    secure: true
  },
  'livraison:lister': {
    url: () => `${baseApi}/livraison/lister`,
    method: 'GET',
    secure: true
  },
  'stock:enregistrer': {
    url: (numero) => `${baseApi}/stock/enregistrer/${numero}`,
    method: 'POST',
    secure: true
  },
  'stock:lister': {
    url: (numero) => `${baseApi}/stock/lister/${numero}`,
    method: 'GET',
    secure: true
  },
  'categorie:enregistrer': {
    url: () => `${baseApi}/codifications/enregistrer/CATEGORIE_ARTICLE`,
    method: 'POST',
    secure: true
  },
  'paiement:enregistrer': {
    url: (numero) => `${baseApi}/paiement/enregistrer/${numero}`,
    method: 'POST',
    secure: true
  },
  'paiement:lister': {
    url: (numero) => `${baseApi}/paiement/lister/${numero}`,
    method: 'GET',
    secure: true
  }
};
