import { LOGOUT_USER } from '../Auth/Auth.constants';
import { STOCK_LISTER, STOCK_ENREGISTRER } from './Stock.constants';

function updateStock(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { data: newData };
}

const initialState = { pageNumero: 0 };

const StockReducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_LISTER:
    case STOCK_ENREGISTRER:
      return {
        ...state,
        ...updateStock(state.data, action.data, action.numero)
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

StockReducer.hydrate = () => initialState;

export default StockReducer;
