import { LOGOUT_USER } from '../Auth/Auth.constants';
import { UPLAOD_DOCUMENTS } from '../Documents/Documents.constants';
import { CLEAN_DASHBOARD_DATAS } from '../Dashboard/Dashboard.constants';
import { STOCK_ENREGISTRER } from '../Stock/Stock.constants';
import {
  ARTICLE_LIRE,
  ARTICLE_ENREGISTRER,
  ARTICLE_LISTER,
  ARTICLE_RECHERCHER,
  ARTICLE_SUPPRIMER
} from './Article.constants';

function updateArticle(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { articles: newData };
}

const initialState = { pageNumero: 0 };

const ArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLE_LISTER:
      return {
        ...state,
        data: action.data
      };
    case ARTICLE_LIRE:
      return {
        ...state,
        ...updateArticle(state.articles, action.data, action.numero)
      };
    case ARTICLE_RECHERCHER:
      return {
        ...state,
        list: action.data,
        pageNumero: action.pageNumero
      };
    case ARTICLE_ENREGISTRER:
    case ARTICLE_SUPPRIMER:
    case STOCK_ENREGISTRER:
      return {
        ...state,
        list: undefined,
        data: undefined,
        articles: undefined,
        pageNumero: 0
      };

    case LOGOUT_USER:
      return {
        ...initialState
      };
    case UPLAOD_DOCUMENTS:
    case CLEAN_DASHBOARD_DATAS:
      return {
        ...state,
        list: undefined,
        articles: undefined,
        pageNumero: 0
      };
    default:
      return state;
  }
};

ArticleReducer.hydrate = () => initialState;

export default ArticleReducer;
