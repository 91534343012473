import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu',
    items: [
      {
        title: 'tableau de bord',
        path: PATH_DASHBOARD.general.app,
        icon: <Icon icon="ant-design:fund-projection-screen-outlined" width="2048" height="2048" />
      },
      {
        title: 'clients',
        path: PATH_DASHBOARD.clients.root,
        icon: <Icon icon="carbon:user-multiple" width="2048" height="2048" />,
        gestionnaire: true
      },
      {
        title: 'commandes',
        path: PATH_DASHBOARD.commandes.root,
        icon: <Icon icon="mdi:order-bool-descending-variant" width="2048" height="2048" />,
        gestionnaire: true
      },
      {
        title: 'livraisons',
        path: PATH_DASHBOARD.livraisons,
        icon: <Icon icon="mdi:motorbike" width="2048" height="2048" />,
        livreur: true
      },
      {
        title: 'infographie',
        path: PATH_DASHBOARD.infographie,
        icon: <Icon icon="el:print" width="2048" height="2048" />,
        infographie: true
      },
      {
        title: 'articles',
        path: PATH_DASHBOARD.articles.root,
        icon: <Icon icon="ooui:articles-ltr" width="2048" height="2048" />,
        gestionnaire: true
      },
      {
        title: 'utilisateurs',
        path: PATH_DASHBOARD.utilisateurs,
        icon: <Icon icon="bxs:user-pin" width="2048" height="2048" />,
        admin: true
      },
      {
        title: 'Communes',
        path: PATH_DASHBOARD.gestionlivraisons,
        icon: <Icon icon="material-symbols:directions-bike" width="2048" height="2048" />,
        admin: true
      },
      {
        title: 'Catégories',
        path: PATH_DASHBOARD.gestioncategories,
        icon: <Icon icon="carbon:list-dropdown" width="2048" height="2048" />,
        admin: true
      },
      {
        title: 'Rapport',
        path: PATH_DASHBOARD.rapport,
        icon: <Icon icon="bi:graph-up-arrow" width="2048" height="2048" />,
        super: true
      }
    ]
  }
];

export default sidebarConfig;
