import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { colors } from '@material-ui/core';
import { MAvatar } from '../@material-extend';

const classes = {
  root: {
    width: '100%',
    backgroundColor: '#F4E8E8'
  },
  dropzone: {
    cursor: 'pointer',
    width: '100%',
    backgroundColor: '#F4E8E8',
    borderRadius: 5,
    height: '100%',
    textAlign: 'center',
    maxHeight: '95px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropzoneDisable: {
    cursor: 'pointer',
    width: '100%',
    backgroundColor: '#FBFBFB',
    borderRadius: 5,
    height: '100%',
    textAlign: 'center',
    maxHeight: '95px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropZoneLabel: {
    verticalAlign: 'middle',
    margin: 5,
    color: colors.grey[400],
    fontStyle: 'italic',
    textAlign: 'center'
  },
  buttonInput: {
    disabled: 'flex',
    textAlign: 'center'
  }
};

FileLoader.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  url: PropTypes.string
};

export default function FileLoader({ handleDrop }) {
  const [files, setFiles] = useState([]);

  function handleDropFile(files) {
    setFiles(files);
    handleDrop(files);
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Dropzone
          className={classes.dropzone}
          maxSize={5000000}
          onDrop={(acceptedFiles) => handleDropFile(acceptedFiles)}
          autoFocus
          accept="image/*"
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography className={classes.dropZoneLabel}>
                  Glissez / déposez un fichier ici, ou cliquez ici pour en choisir
                </Typography>
              </div>
            </section>
          )}
        </Dropzone>

        <Typography component="div" style={{ display: 'flex' }}>
          {files.length > 0 &&
            files.map((file, index) => (
              <Typography component="div" key={`photo${index}`} style={{ marginLeft: 10 }}>
                <MAvatar src={URL.createObjectURL(file)} alt={`photo${index}`} color="default" />
              </Typography>
            ))}
        </Typography>
      </CardContent>
    </Card>
  );
}
