import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import ApiReducer from './slices/Api/Api.reducer';
import AuthReducer from './slices/Auth/Auth.reducer';
import DashboardReducer from './slices/Dashboard/Dashboard.reducer';
import RapportReducer from './slices/Rapport/Rapport.reducer';
import DocumentsReducer from './slices/Documents/Documents.reducer';
import ArticleReducer from './slices/Articles/Article.reducer';
import ClientReducer from './slices/Clients/Clients.reducer';
import CommandeReducer from './slices/Commandes/Commandes.reducer';
import LivraisonReducer from './slices/Livraison/Livraison.reducer';
import StockReducer from './slices/Stock/Stock.reducer';
import PaiementReducer from './slices/Paiement/Paiement.reducer';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  api: ApiReducer,
  auth: AuthReducer,
  dashboard: DashboardReducer,
  rapport: RapportReducer,
  documents: DocumentsReducer,
  articles: ArticleReducer,
  clients: ClientReducer,
  commandes: CommandeReducer,
  livraison: LivraisonReducer,
  stocks: StockReducer,
  paiements: PaiementReducer
});

export { rootPersistConfig, rootReducer };
